<template>
  <div>
    <div class='perInfo A4Container flexCloumnCenter pageBreak_no'>
      <div class='form'>
        <div class='table' style='height: 100%;'>
          <el-row class='flexStretch'>
            <el-col :span='20'>
              <div style='width: 100%;'>
                <el-row>
                  <el-col :span='6' class='cell cell50 flexCenter' style='border-left: 0;'>姓名</el-col>
                  <el-col :span='6' class='cell cell50 flexCenter'>{{ stuinfo.stu_name }}</el-col>
                  <el-col :span='3' class='cell cell50 flexCenter'>性别</el-col>
                  <el-col :span='3' class='cell cell50 flexCenter'>
                    {{ stuinfo.sexName || '-' }}
                  </el-col>
                  <el-col :span='3' class='cell cell50 flexCenter'>年龄</el-col>
                  <el-col :span='3' class='cell cell50 flexCenter'>{{ stuinfo.age }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span='6' class='cell cell50 flexCenter' style='border-left: 0;'>家庭住址</el-col>
                  <el-col :span='18' class='cell cell50 flexCenter'>{{ stuinfo.address }}
                  </el-col>

                </el-row>
                <el-row>
                  <el-col :span='6' class='cell cell50 flexCenter' style='border-left: 0;'>实习时间</el-col>
                  <el-col :span='18' class='cell cell50 flexCenter'>
                    自{{ stuinfo.startTime || '-' }}起；至{{ stuinfo.endTime || "-" }}止
                  </el-col>

                </el-row>
              </div>
            </el-col>
            <el-col :span='4' class='cellMore flexCenter'>
              <div class='flexCenter' v-if='stuinfo.realPhoto'
                style='width: 100%;height: 150px;box-sizing:border-box;padding:1px;overflow: hidden'>
                <img v-if='stuinfo.realPhoto' :src='stuinfo.realPhoto' alt='' style='width: 100%;'>
              </div>
              <span v-else>贴照<br />片处</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span='5' class='flexCenter cell cell46' style='border-left:0;'>实习单位</el-col>
            <el-col :span='5' class='flexCenter cell cell46'>实习科目</el-col>
            <el-col :span='5' class='flexCenter cell cell46'>实习起止时间</el-col>
            <el-col :span='9' class='flexCenter cell cell46'
              style='border-right:0;'>备注（包括缺席实习时间记载）</el-col>
          </el-row>
          <el-row v-for='(item, index) in kslist'>
            <el-col :span='5' class='flexCenter cell cell46 ' style='border-left:0;line-height: unset;padding: 0 3px'>
              <div class='overMoreLine'>{{ item.firm_name }}</div>
            </el-col>
            <el-col :span='5' class='flexCenter cell cell46' style='line-height: unset;padding: 0 3px'>
              <div class='overMoreLine'>{{ item.subject }}</div>
            </el-col>
            <el-col :span='5' class='flexCloumnCenter cell cell46 unsetlineheight'>
              <div class='flexBetween' style='width: 100%;padding: 0 3px'>
                <div class=''>自</div>{{ item.start_time }}<div class=''>起</div>
              </div>
              <div class='flexBetween' style='width: 100%;padding: 0 3px'>
                <div class=''>至</div>{{ item.end_time }}<div class=''>止</div>
              </div>
            </el-col>
            <el-col :span='9' class='flexCenter cell cell46' style='border-right:0;'></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Object,
      default: {}
    },
    kslist: {
      type: Array,
      default: [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
      ]
    }
  },
  data() {
    return {
      isShowModal: true,
      list: [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
      ]
    }
  },
  mounted() {
    // console.log(this.kslist)
  },
  methods: {
    closeDialog() {
      this.$parent.closeYY()
    }
  }
}
</script>

<style lang="less" type="text/less">
.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
@font-face {
  font-family: "华文中宋";
  src: url("/public/font/hwzs.ttf");
}

.fontFamily_HWZS {
  font-family: '华文中宋';
}

.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: '华文中宋';

    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: '华文中宋';

  }

  .cell46 {
    min-height: 48.2px;
    line-height: unset;
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: '华文中宋';

  }
}

.uline {
  text-decoration: underline;
}
</style>
