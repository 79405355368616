<template>
  <div>
    <div class="stuContact A4Container flexCloumnCenter pageBreak_no">
      <div class="form">

        <div class="flexCloumnSingleCenter" style="width: 100%;height: 100%;">

          <div style="width: 100%;height: 100%;">
            <div class="content a-row" style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
              <div class="title flexCenter fontFamily_HWZS">毕 业 实 习 自 我 鉴 定</div>
              <div :id="'divCntnrA_'+idx"
                   style="width:100%;height: calc( 100% - 100px );overflow: hidden;">
                <div :id="'resizeDivA_'+idx" class="fontFamily_HWZS" style="width: 100%;word-break: break-all">
                  {{ info.content }}
                </div>
              </div>

              <div style="position: absolute;bottom: 10px;right: 10px;line-height: unset!important;">
                <div class="flexStart" style="line-height: unset!important;">
                  <div class='flexStart'>
                    <div class='fontFamily_HWZS'>签名：</div>
                    <img v-if="info.sign" :src="info.sign" alt=""
                         style="width: 100px;max-height: 50px;">
                    <div v-else style="width: 100px;height: 50px;"></div>
                  </div>

                  <div v-if='info.sign_date' class="fontFamily_HWZS" style="text-indent:0;">{{info.sign_date }}</div>
                  <div class='' v-else>
                    年<span style="display:inline-block;width: 20px;height: 10px"></span>
                    月<span style="display:inline-block;width: 20px;height: 10px"></span>
                    日
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'zhoubao',
  data () {
    return {
      dataover: false,
      userid: '',
      info:{}
    }
  },
  props: {
    info1: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
  },
  mounted () {
    let _this = this
    setTimeout(function () {
      let info = JSON.parse(JSON.stringify(_this.info1))
      if(info.content){
        info.content = _this.stripscript(info.content)
        _this.info = {...info}
      }
      if (info.sign) {
          info.sign += `?x-oss-process=image/resize,m_fill,h_50,w_100`
        _this.info = { ...info }
      }
      if (info.sign_date) {
        let arr = info.sign_date.split('-')
        info.sign_date = `${arr[0]}年${arr[1]}月${arr[2]}日`
        _this.info = { ...info }
      }
      _this.resizeA()
      // _this.resizeB()
    }, 500)
  },
  methods: {
    stripscript (s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA () {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB () {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    }
  }
}
</script>

<style lang="less" type="text/less">
.stuContact .ant-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.stuContact .ant-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>

.stuContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    width: 100%;
    font-size: 20px!important;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;
    overflow: hidden;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 0 10px 10px 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .a-row {
      border-top: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
